import React from 'react';
import {graphql, navigate} from "gatsby";
import Layout from "../../components/Layout/en";
import {Button, ButtonGroup, Container} from "@mui/material";
import Roadmap from "../../components/Roadmap/Roadmap";


function BackEndRoadmap({data, location}) {

  const roadmapData = data.allMarkdownRemark.group.filter((item) => item.fieldValue === "back")[0];
  const sortedData = !!roadmapData && roadmapData.edges
    .sort((a, b) => {
      const aOrder = a.node.frontmatter.order_be;
      const bOrder = b.node.frontmatter.order_be;
      if (aOrder > bOrder) return 1;
      if (aOrder < bOrder) return -1;
      return 0;
    })

  return (
    <Layout
      title='JS Back End Roadmap'
      description='Here I described the order of learning technologies for JavaScript Back End developers'
      location={location}>
      <Container>
        <div style={{textAlign: "center", padding: "15px 0"}}>
          <h1 style={{textTransform: "capitalize"}}>JS Back End Roadmap {new Date().getFullYear()}</h1>
          <ButtonGroup variant="contained" aria-label="outlined primary button group">
            <Button
              onClick={() => {navigate("/en/front-end-roadmap")}}
              style={{backgroundColor: 'black', borderColor: 'white'}}
            >
              Frontend
            </Button>
            <Button
              onClick={() => {navigate("/en/back-end-roadmap")}}
              style={{backgroundColor: 'black', borderColor: 'white'}}>
              Backend
            </Button>
          </ButtonGroup>
        </div>
        {!!roadmapData && <Roadmap roadmapData={sortedData} />}
      </Container>
    </Layout>
  );
}

export const query = graphql`
  {
    allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "roadmap"}}, fields: {langKey: {eq: "en"}}}) {
      group(field: frontmatter___category) {
        edges {
          node {
            id
            frontmatter {
              title
              description
              links {
                link {
                  name
                  type
                  url
                }
              }
              order_be
              order_fe
            }
          }
        }
        fieldValue
      }
    }
  }
`

export default BackEndRoadmap;